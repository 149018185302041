import {
  convertToSentence,
  CreatePaymentType,
  PaymentMethodType,
  PaymentType,
  SoftDeleteFlag,
  UpdatePaymentType,
} from '@bofrak-backend/shared';
import { Loader } from '@bofrak-backend/shared-ui';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { apiAdapter } from '../api/backend';
import ModalComponent from '../components/resuable/Modal';
import { merchantAtom, selectedStoreAtom } from '../recoil/atoms';

interface PaymentTypesProps {
  paymentTypes: PaymentType[] | undefined;
}

const paymentsTypes = Object.values(PaymentMethodType);

const PaymentTypesList = ({ paymentTypes }: PaymentTypesProps) => {
  // Update modal (for editing)
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Delete confirmation modal
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  const currentStore = useRecoilValue(selectedStoreAtom);
  const [selectedPaymentType, setSelectedPaymentType] =
    useState<PaymentType | null>(null);
  const queryClient = useQueryClient();
  const toast = useToast();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      payment_type: '',
    },
  });

  // Mutation for updating payment type (used for general updates and for removal from a store)
  const { isLoading, mutateAsync } = useMutation(
    'update-payment-type',
    (data: UpdatePaymentType) => apiAdapter.updatePaymentType(data),
    {
      onSuccess: (data) => {
        if (data) {
          onClose();
          queryClient.invalidateQueries('get-payment-types');
          reset();
          toast({
            title: 'Payment type updated successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      },
      onError: (error: AxiosError) => {
        toast({
          title: 'Failed to update payment type',
          description: (
            (error.response?.data as any) ?? { message: error.message }
          ).message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        console.error(error);
      },
    },
  );

  // Mutation for deleting payment type from all stores
  const { isLoading: isDeleting, mutateAsync: deletePaymentTypeMutation } =
    useMutation(
      'delete-payment-type',
      (paymentType: PaymentType) =>
        apiAdapter.deletePaymentType({
          merchant_id: currentStore?.merchant_id as string,
          id: paymentType.id,
        }),
      {
        onSuccess: () => {
          toast({
            title: 'Payment type deleted successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          queryClient.invalidateQueries('get-payment-types');
        },
        onError: (error: AxiosError) => {
          toast({
            title: 'Failed to delete payment type',
            description: (
              (error.response?.data as any) ?? { message: error.message }
            ).message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );

  // Local state for delete confirmation details
  const [deleteOption, setDeleteOption] = useState<
    'remove' | 'deleteAll' | null
  >(null);
  const [deleteConfirmText, setDeleteConfirmText] = useState('');

  const handleClickPaymentType = (paymentType: PaymentType) => {
    setSelectedPaymentType(paymentType);
    reset({
      name: paymentType.name,
      payment_type: paymentType.payment_type,
    });
    onOpen();
  };

  // Remove payment type from the current store (update with remove_stores)
  const handleRemoveFromStore = async () => {
    if (!selectedPaymentType) return;
    const dtoObject: UpdatePaymentType = {
      id: selectedPaymentType.id,
      remove_stores: [currentStore?.id as string],
    };
    try {
      await mutateAsync(dtoObject);
      onDeleteModalClose();
      toast({
        title: 'Payment type removed from store successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Delete payment type from all stores (calls delete API)
  const handleDeleteAll = async () => {
    if (!selectedPaymentType) return;
    try {
      await deletePaymentTypeMutation(selectedPaymentType);
      onDeleteModalClose();
      toast({
        title: 'Payment type deleted from all stores successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data: any) => {
    const dtoObject: UpdatePaymentType = {
      name: data.name as string,
      payment_type: data.payment_type as PaymentMethodType,
      add_stores: [currentStore?.id as string],
      id: selectedPaymentType?.id as string,
    };
    try {
      await mutateAsync(dtoObject);
    } catch (err) {
      console.log(err);
    }
  };

  const isSelectedPaymentTypeInStore = selectedPaymentType?.stores
    ?.map((x) => x.toLowerCase())
    .includes(currentStore?.id.toLowerCase() as string);

  return (
    <Box width="full" p={4} boxShadow="md">
      {/* Table Header */}
      <Table maxW="full" variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Payment Type</Th>
            <Th>Stores</Th>
          </Tr>
        </Thead>
        <Tbody>
          {paymentTypes?.map((paymentType, index) => {
            const isPaymentTypeInStore = paymentType.stores
              ?.map((x) => x.toLowerCase())
              .includes(currentStore?.id.toLowerCase() as string);

            return (
              <Tr
                key={index}
                bg={index % 2 === 0 ? '#F7FAFC' : 'white'}
                onClick={() => handleClickPaymentType(paymentType)}
                cursor="pointer">
                <Td>
                  <Text>{paymentType.name}</Text>
                </Td>
                <Td px={0} textTransform={'uppercase'}>
                  {convertToSentence(paymentType.payment_type)}
                </Td>
                <Td px={0}>
                  <Text
                    fontSize="xs"
                    color={isPaymentTypeInStore ? 'green.500' : 'red.500'}>
                    {isPaymentTypeInStore ? 'Used In Store' : 'Unused In Store'}
                  </Text>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      {/* Modal for Editing Payment Type */}
      <ModalComponent isOpen={isOpen} onClose={onClose}>
        <Center mt={10} width="full">
          <Text color="gray.700" fontWeight="bold">
            Update Payment Type
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name')}
                placeholder="Enter payment type name"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Payment Method Type</FormLabel>
              <Select
                {...register('payment_type')}
                placeholder="Select payment method type">
                {paymentsTypes.map((paymentType, index) => (
                  <option key={index} value={paymentType}>
                    {convertToSentence(paymentType)}
                  </option>
                ))}
              </Select>
            </FormControl>
            <HStack justifyContent="space-around" width="full">
              <Button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                  onDeleteModalOpen();
                }}
                colorScheme="red"
                isLoading={isDeleting}>
                Delete
              </Button>
              <Button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  const dtoObject: UpdatePaymentType = {
                    id: selectedPaymentType?.id as string,
                    add_stores: isSelectedPaymentTypeInStore
                      ? []
                      : [currentStore?.id as string],
                    remove_stores: isSelectedPaymentTypeInStore
                      ? [currentStore?.id as string]
                      : [],
                  };
                  mutateAsync(dtoObject);
                }}
                colorScheme={isSelectedPaymentTypeInStore ? 'pink' : 'purple'}
                isLoading={isLoading}>
                {isSelectedPaymentTypeInStore
                  ? 'Remove from Store'
                  : 'Add to Store'}
              </Button>
              <Button type="submit" colorScheme="blue" isLoading={isLoading}>
                Update
              </Button>
            </HStack>
          </VStack>
        </form>
      </ModalComponent>

      {/* Delete Confirmation Modal */}
      <ModalComponent
        isOpen={isDeleteModalOpen}
        onClose={() => {
          onDeleteModalClose();
          setDeleteOption(null);
        }}>
        <Center mt={10} width="full">
          <Text color="gray.700" fontWeight="bold">
            Confirm Deletion
          </Text>
        </Center>
        {deleteOption === null && (
          <VStack spacing={4}>
            <Text>
              Do you want to remove the payment type from this store, or delete
              it from all stores?
            </Text>
            <HStack spacing={4}>
              <Button colorScheme="blue" onClick={handleRemoveFromStore}>
                Remove from Store
              </Button>
              <Button
                colorScheme="red"
                onClick={() => setDeleteOption('deleteAll')}>
                Delete from All Stores
              </Button>
            </HStack>
            <Button variant="ghost" onClick={onDeleteModalClose}>
              Cancel
            </Button>
          </VStack>
        )}
        {deleteOption === 'deleteAll' && (
          <VStack spacing={4}>
            <Text>
              Please type "Delete" to confirm deletion from all stores.
            </Text>
            <Input
              value={deleteConfirmText}
              onChange={(e) => setDeleteConfirmText(e.target.value)}
              placeholder='Type "Delete"'
            />
            <HStack spacing={4}>
              <Button
                colorScheme="red"
                onClick={handleDeleteAll}
                isDisabled={deleteConfirmText !== 'Delete'}>
                Confirm Delete
              </Button>
              <Button onClick={onDeleteModalClose}>Cancel</Button>
            </HStack>
          </VStack>
        )}
      </ModalComponent>
    </Box>
  );
};

const ManagePaymentTypes = () => {
  const {
    isOpen: isPaymentModalOpen,
    onOpen: onPaymentModalOpen,
    onClose: onPaymentModalClose,
  } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const merchant = useRecoilValue(merchantAtom);
  const currentStore = useRecoilValue(selectedStoreAtom);
  const [paymentTypesPage, setPaymentTypesPage] = useState<PaymentType[]>([]);
  const queryClient = useQueryClient();

  const { status } = useQuery(
    ['get-payment-types', currentStore?.id, merchant?.id],
    () =>
      apiAdapter.getPaymentTypes({
        merchant_id: merchant?.id as string,
        limit: 250,
        include_deleted: SoftDeleteFlag.EXCLUDE_DELETED,
      }),
    {
      onSuccess: (data) => {
        if (data) {
          const payments = data.payment_types;
          setPaymentTypesPage(payments as PaymentType[]);
        }
      },
    },
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      payment_type: '',
    },
  });

  const { isLoading, mutateAsync } = useMutation(
    'create-payment-type',
    (data: CreatePaymentType) => apiAdapter.createPaymentType(data),
    {
      onSuccess: (data) => {
        if (data) {
          onPaymentModalClose();
          queryClient.invalidateQueries('get-payment-types');
          reset();
          toast({
            title: 'Payment type created successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      },
      onError: (error: AxiosError) => {
        toast({
          title: 'Failed to create payment type',
          description: (
            (error.response?.data as any) ?? { message: error.message }
          ).message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        console.error(error);
      },
    },
  );

  const onSubmit = async (data: any) => {
    const dataObj: CreatePaymentType = {
      name: data.name as string,
      payment_type: data.payment_type as PaymentMethodType,
      stores: [currentStore?.id as string],
      merchant_id: merchant?.id as string,
    };

    try {
      await mutateAsync(dataObj);
    } catch (error) {
      console.log(error);
    }
  };

  if (status === 'loading') return <Loader />;

  return (
    <Box p={4}>
      <Button size="sm" onClick={() => navigate(-1)} mb={4}>
        Back
      </Button>
      <Text fontSize="lg" textAlign="center" fontWeight="bold">
        Payment Types
      </Text>
      <Flex justify="flex-end">
        <Button
          size="sm"
          mb={4}
          onClick={onPaymentModalOpen}
          colorScheme="blue">
          + Add
        </Button>
      </Flex>
      {paymentTypesPage.length > 0 ? (
        <PaymentTypesList paymentTypes={paymentTypesPage} />
      ) : (
        <Center mt={4}>
          <Text>No payment types found</Text>
        </Center>
      )}

      {/* Modal for Creating Payment Type */}
      <ModalComponent isOpen={isPaymentModalOpen} onClose={onPaymentModalClose}>
        <Center mt={10} width="full">
          <Text color="gray.700" fontWeight="bold">
            Add Payment Type
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name', { required: 'This field is required' })}
                placeholder="Enter payment type name"
              />
              {errors.name && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Payment Method Type</FormLabel>
              <Select
                {...register('payment_type', {
                  required: 'This field is required',
                })}
                placeholder="Select payment method type">
                {paymentsTypes.map((paymentType, index) => (
                  <option key={index} value={paymentType}>
                    {paymentType}
                  </option>
                ))}
              </Select>
              {errors.payment_type && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <Flex justify="flex-end" width="full">
              <Button isLoading={isLoading} type="submit" colorScheme="blue">
                Add
              </Button>
            </Flex>
          </VStack>
        </form>
      </ModalComponent>
    </Box>
  );
};

export default ManagePaymentTypes;
