import {
  CreateTax,
  SoftDeleteFlag,
  Tax,
  TaxType,
  UpdateTax,
} from '@bofrak-backend/shared';
import { Loader } from '@bofrak-backend/shared-ui';
import { apiAdapter } from '@bofrak-backend/shared-ui/src/utils/api';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ModalComponent from '../components/resuable/Modal';
import { merchantAtom, selectedStoreAtom } from '../recoil/atoms';
import { MdDelete } from 'react-icons/md';

interface TaxesProps {
  taxes: Tax[] | undefined;
  refetchTaxes: () => void;
}

const TaxesList = ({ taxes, refetchTaxes }: TaxesProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTax, setSelectedTax] = useState<Tax | null>(null);
  const merchant = useRecoilValue(merchantAtom);
  const toast = useToast();
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: selectedTax?.name,
      rate: selectedTax?.rate,
      type: selectedTax?.type,
    },
  });

  // Mutation for updating tax
  const { isLoading, mutateAsync } = useMutation(
    'update-tax',
    (data: UpdateTax) => apiAdapter.updateTax(data),
    {
      onSuccess: (data) => {
        if (data) {
          onClose();
          reset();
          toast({
            title: 'Tax Updated successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      },
      onError: (error: AxiosError) => {
        toast({
          title: 'Failed to Update tax',
          description: (
            (error.response?.data as any) ?? { message: error.message }
          ).message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        console.error(error);
      },
    },
  );

  // Delete mutation using react-query
  const { isLoading: isDeleting, mutateAsync: deleteTaxMutation } = useMutation(
    'delete-tax',
    (tax: Tax) =>
      apiAdapter.deleteTax({
        merchant_id: merchant?.id as string,
        id: tax.id,
      }),
    {
      onSuccess: () => {
        toast({
          title: 'Tax deleted successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        refetchTaxes();
      },
      onError: (error: AxiosError) => {
        toast({
          title: 'Failed to delete tax',
          description: (
            (error.response?.data as any) ?? { message: error.message }
          ).message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const handleClickTax = (tax: Tax) => {
    setSelectedTax(tax);
    reset({
      name: tax.name,
      rate: tax.rate,
      type: tax.type,
    });
    onOpen();
  };

  // Called when clicking the delete icon in a tax row
  const handleDeleteTax = async (tax: Tax, e: React.MouseEvent) => {
    e.stopPropagation(); // prevent row click from triggering
    try {
      await deleteTaxMutation(tax);
    } catch (error) {
      console.log(error);
    }
  };

  // Called when clicking the Delete button in the modal
  const handleDeleteSelectedTax = async () => {
    if (!selectedTax) return;
    try {
      await deleteTaxMutation(selectedTax);
      setSelectedTax(null);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data: any) => {
    if (!merchant) return;
    if (!selectedTax) return;

    const dtoObject: UpdateTax = {
      merchant_id: merchant.id,
      id: selectedTax.id,
    };

    if (data.name !== selectedTax.name) dtoObject.name = data.name;
    if (data.rate !== selectedTax.rate) dtoObject.rate = Number(data.rate);
    if (data.type !== selectedTax.type) dtoObject.type = data.type;

    try {
      await mutateAsync(dtoObject);
      refetchTaxes();
    } catch (error) {
      console.log(error);
    }
  };

  if (!merchant) return <Loader />;

  return (
    <Box width="full" p={4} boxShadow="md">
      {/* Header */}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Rate</Th>
            <Th>Type</Th>
          </Tr>
        </Thead>
        <Tbody>
          {taxes?.map((tax, index) => (
            <Tr
              key={index}
              bg={index % 2 === 0 ? '#F7FAFC' : 'white'}
              onClick={() => handleClickTax(tax)}
              cursor="pointer">
              <Td fontWeight="500">{tax.name}</Td>
              <Td>{tax.rate}</Td>
              <Td>
                <HStack justifyContent={'space-between'}>
                  <Text>{tax.type}</Text>
                  <IconButton
                    bg="transparent"
                    color="red"
                    isLoading={isDeleting}
                    aria-label="Delete Tax"
                    icon={<MdDelete />}
                    onClick={(e) => handleDeleteTax(tax, e)}
                  />
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {/* Modal for Editing Tax */}
      <ModalComponent isOpen={isOpen} onClose={onClose}>
        <Center mt={10} width="full">
          <Text color="gray.700" fontWeight="bold">
            Update Tax
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input {...register('name')} placeholder="Enter tax name" />
            </FormControl>

            <FormControl>
              <FormLabel>Rate</FormLabel>
              <Input
                {...register('rate')}
                placeholder="Enter tax rate"
                type="number"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select
                {...register('type', {
                  required: 'This field is required',
                })}
                placeholder="Select tax type">
                {Object.values(TaxType).map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
            </FormControl>

            <HStack justifyContent={'space-around'} width={'full'}>
              <Button
                type="button"
                onClick={handleDeleteSelectedTax}
                colorScheme="red"
                isLoading={isDeleting}>
                Delete
              </Button>
              <Button type="submit" colorScheme="blue" isLoading={isLoading}>
                Update
              </Button>
            </HStack>
          </VStack>
        </form>
      </ModalComponent>
    </Box>
  );
};

interface DefaultValues {
  name: string;
  rate: number;
  type: TaxType;
}

const ManageTaxes = () => {
  const {
    isOpen: isTaxModalOpen,
    onOpen: onTaxModalOpen,
    onClose: onTaxModalClose,
  } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const currentStore = useRecoilValue(selectedStoreAtom);
  const [taxesPage, setTaxesPage] = useState<Tax[]>([]);
  const merchant = useRecoilValue(merchantAtom);

  const { status, refetch: refetchTaxes } = useQuery(
    ['get-taxes', merchant?.id, currentStore?.merchant_id],
    () =>
      apiAdapter.getTaxes({
        merchant_id: merchant?.id ?? '',
        limit: 250,
        include_deleted: SoftDeleteFlag.EXCLUDE_DELETED,
      }),
    {
      enabled: !!currentStore?.merchant_id,
      onSuccess: (data) => {
        if (data) {
          setTaxesPage(data.taxes);
        }
      },
    },
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DefaultValues>({
    defaultValues: {
      name: '',
      rate: 0,
      type: TaxType.INCLUDED,
    },
  });

  const { isLoading, mutateAsync } = useMutation(
    'create-tax',
    (data: CreateTax) => apiAdapter.createTax(data),
    {
      onSuccess: (data) => {
        if (data) {
          onTaxModalClose();
          reset();
          toast({
            title: 'Tax created successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      },
      onError: (error: AxiosError) => {
        toast({
          title: 'Failed to create tax',
          description: (
            (error.response?.data as any) ?? { message: error.message }
          ).message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        console.error(error);
      },
    },
  );

  const onSubmit = async (data: any) => {
    if (!currentStore?.id) return;
    const dataObj: CreateTax = {
      name: data.name as string,
      rate: Number(data.rate),
      type: data.type as TaxType,
      merchant_id: merchant?.id as string,
    };
    try {
      await mutateAsync(dataObj);
      refetchTaxes();
    } catch (error) {
      console.log(error);
    }
  };

  if (status === 'loading') return <Loader />;

  return (
    <Box p={4}>
      <Button size="sm" onClick={() => navigate(-1)} mb={4}>
        Back
      </Button>
      <Text fontSize="lg" textAlign={'center'} fontWeight="bold">
        Taxes
      </Text>
      <Flex justify={'flex-end'}>
        <Button size="sm" mb={4} onClick={onTaxModalOpen} colorScheme="blue">
          + Add Tax
        </Button>
      </Flex>
      {taxesPage.length > 0 ? (
        <TaxesList taxes={taxesPage} refetchTaxes={refetchTaxes} />
      ) : (
        <Center mt={4}>
          <Text>No taxes found</Text>
        </Center>
      )}

      <ModalComponent isOpen={isTaxModalOpen} onClose={onTaxModalClose}>
        <Center mt={10} width="full">
          <Text color="gray.700" fontWeight="bold">
            Add Tax
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name', {
                  required: 'This field is required',
                })}
                placeholder="Enter tax name"
              />
              {errors.name && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Rate</FormLabel>
              <Input
                {...register('rate', {
                  required: 'This field is required',
                })}
                placeholder="Enter tax rate"
                type="number"
              />
              {errors.rate && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select
                {...register('type', {
                  required: 'This field is required',
                })}
                placeholder="Select tax type">
                {Object.values(TaxType).map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
            </FormControl>

            <Flex justify="flex-end" width={'full'}>
              <Button isLoading={isLoading} type="submit" colorScheme="blue">
                Add
              </Button>
            </Flex>
          </VStack>
        </form>
      </ModalComponent>
    </Box>
  );
};

export default ManageTaxes;
