import { Box, Text, VStack } from '@chakra-ui/react';
import { apiAdapter } from '../../utils/api';
import { useQuery } from 'react-query';

interface MenuTriggerProps {
  stage: string;
  accessToken?: string;
  applicationName: string;
  storeId: string;
  showMenu?: boolean;
  encryptedCredentials?: string;
  merchantId: string;
  signOut: () => Promise<void>;
}

export const MenuTrigger: React.FC<MenuTriggerProps> = (props) => {
  const {
    stage = 'unknown',
    applicationName,
    storeId,
    merchantId,
    signOut,
  } = props;

  const { data: store } = useQuery({
    queryKey: ['get-store', storeId],
    queryFn: () => apiAdapter.getStore(storeId, merchantId),
    enabled: !!merchantId && !!storeId,
  });

  let storeName = store?.name;

  const domain = `shopnsmile.org`;

  return (
    <Box>
      <VStack
        spacing={0}
        cursor={'pointer'}
        color={stage.includes('prod') ? 'blue' : 'red'}
        onClick={async () => {
          // Redirect to the autheticator app
          await signOut();
          window.location.href = `https://authenticator.${stage.toLowerCase()}.${domain}`;
        }}>
        <Text fontWeight={'black'} textAlign="center">
          {applicationName?.charAt(0)?.toUpperCase() +
            applicationName?.slice(1)}
        </Text>
        <Text
          textAlign={'center'}
          fontSize={'12px'}
          fontWeight={'black'}
          cursor={'pointer'}>
          {storeName}
        </Text>
      </VStack>
    </Box>
  );
};
