import {
  Loader,
  NavBar,
  StoreSelectionModal,
  useAuth,
} from '@bofrak-backend/shared-ui';
import {
  Box,
  Button,
  Center,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Router } from './Router';
import { apiAdapter } from './api/backend';
import {
  adminAtom,
  merchantAtom,
  selectedStoreAtom,
  storesAtom,
} from './recoil/atoms';
import { envVars, paths } from './utils/constants';
import { AxiosError } from 'axios';

function App() {
  const query = new URLSearchParams(useLocation().search);
  const toast = useToast();
  const [admin, setAdmin] = useRecoilState(adminAtom);
  const [merchant, setMerchant] = useRecoilState(merchantAtom);
  const {
    employee,
    signOut,
    currentStore,
    isLoading,
    errorMessage,
    availableStores,
    requiresStoreSelection,
    setCurrentStoreById,
  } = useAuth({
    applicationName: merchant?.business_name ?? '',
    stage: envVars.STAGE,
  });

  const [selectedStore, setSelectedStore] = useRecoilState(selectedStoreAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const setStoresPage = useSetRecoilState(storesAtom);

  const { data: storesData } = useQuery(
    ['get-stores', { merchant_id: merchant?.id }],
    () => apiAdapter.getStores(merchant?.id ?? ''),
    {
      enabled: !!merchant?.id,
    },
  );

  useEffect(() => {
    if (storesData?.stores) {
      setStoresPage(storesData);
    }
  }, [storesData]);

  useQuery(
    ['get-merchant', { merchant_id: selectedStore?.merchant_id }, merchant?.id],
    () => apiAdapter.getMerchant(selectedStore?.merchant_id as string),
    {
      enabled: !!selectedStore,
      onSuccess: (data) => {
        if (data) {
          setMerchant(data);
        } else {
          toast({
            title: 'No Merchant Information on this account',
            description: 'Please contact your administrator',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });

          // Wait for 9 seconds before signing out
          setTimeout(() => {
            signOut();
          }, 9000);
        }
      },
      onError: (error: AxiosError) => {
        toast({
          title: 'Error',
          description: (
            (error.response?.data as any) ?? { message: error.message }
          ).message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    },
  );

  useEffect(() => {
    if (employee && selectedStore && selectedStore.id) {
      const hasToken = query.has('token');
      const hasStoreId = query.has('store_id');

      if (hasToken || hasStoreId) {
        query.delete('token');
        query.delete('store_id');
        const newParamsString = query.toString();

        navigate(
          {
            pathname: window.location.pathname,
            search: newParamsString ? `?${newParamsString}` : '',
          },
          { replace: true },
        );
      }
    }
  }, [employee, selectedStore]);

  useEffect(() => {
    setAdmin(employee);

    async function fetchStore(id: string, merchant_id: string) {
      try {
        const store = await apiAdapter.getStore(id, merchant_id);
        setSelectedStore(store);
      } catch (error) {
        console.error('Failed to fetch store:', error);
      }
    }

    const storeIdFromUrl = query.get('store_id');
    if (storeIdFromUrl && employee) {
      fetchStore(storeIdFromUrl, employee.merchant_id as string);
    } else {
      if (currentStore) {
        setSelectedStore(currentStore);
      }
    }

    if (selectedStore && currentStore && selectedStore.id !== currentStore.id) {
      setSelectedStore(currentStore);
    }
  }, [employee, query, currentStore]);

  // Open the modal if store selection is required
  useEffect(() => {
    if (requiresStoreSelection) {
      onOpen();
    }
  }, [requiresStoreSelection, onOpen]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh">
        <Loader />
      </Box>
    );
  }

  if (errorMessage) {
    return (
      <Box p={4}>
        <Text color="red.500">{errorMessage}</Text>
        <Button onClick={signOut} mt={4} colorScheme="blue">
          Sign Out
        </Button>
      </Box>
    );
  }

  return employee ? (
    <Flex
      direction={{
        base: 'column',
      }}
      width={'100%'}
      maxW={'100%'}
      minH={'100vh'}
      bg={'light-background'}>
      <NavBar
        appName="Settings"
        homePath={paths.home}
        merchant={merchant?.business_name ? merchant.business_name : ''}
        signOut={signOut}
        stage={envVars.STAGE}
        setCurrentStore={setSelectedStore}
        storeId={currentStore?.id as string}
        user={employee}
        merchantId={merchant ? merchant.id : ''}
        availableStores={availableStores}
        onChangeStore={setCurrentStoreById}
        shouldShowStoreSwitcher={true}
      />
      {merchant && merchant?.id && (
        <Center mt={4}>
          <Text color={'gray.600'} fontWeight={'bold'}>
            {merchant?.business_name}
          </Text>
        </Center>
      )}
      {admin && <Router />}
      <StoreSelectionModal
        availableStores={availableStores}
        isOpen={isOpen}
        onClose={() => {
          // Prevent closing the modal without selecting a store
          if (!currentStore) {
            return;
          }
          onClose();
        }}
        onSelectStore={setCurrentStoreById}
      />
    </Flex>
  ) : null;
}

export default App;
