import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import NewEmployee from '../components/NewEmployee';
import NewRole from '../components/NewRole';
import ModalComponent from '../components/resuable/Modal';
import RoleList from '../components/RoleList';
import EmployeeList from '../components/EmployeeList';

const ManageUsers = () => {
  const navigate = useNavigate();

  const {
    isOpen: isUsersModalOpen,
    onOpen: onUsersModalOpen,
    onClose: onUsersModalClose,
  } = useDisclosure();

  const {
    isOpen: isRolesModalOpen,
    onOpen: onRolesModalOpen,
    onClose: onRolesModalClose,
  } = useDisclosure();

  return (
    <Box p={4}>
      <Button size="sm" onClick={() => navigate(-1)} mb={4}>
        Back
      </Button>

      <Tabs isManual variant="enclosed" width="100%" isLazy>
        <TabList
          // Make the TabList sticky
          position="sticky"
          top={0}
          zIndex={1}
          bg="gray.50"
          boxShadow="md"
          padding={'0'}>
          <Tab width="50%">Employee List</Tab>
          <Tab width="50%">Roles</Tab>
        </TabList>

        <TabPanels>
          {/* Employee List Panel */}
          <TabPanel
            width={'full'}
            style={{
              padding: '0',
            }}>
            <Flex justify={'flex-end'} align="center">
              <Button
                size="sm"
                onClick={onUsersModalOpen}
                colorScheme="blue"
                my={4}>
                + Add Employee
              </Button>
            </Flex>
            <EmployeeList />
          </TabPanel>

          {/* Roles Panel */}
          <TabPanel
            width={'full'}
            style={{
              padding: '0',
            }}>
            <Flex justify={'flex-end'} align="center">
              <Button
                size="sm"
                onClick={onRolesModalOpen}
                colorScheme="blue"
                my={4}>
                + Add Role
              </Button>
            </Flex>
            <RoleList />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <ModalComponent isOpen={isUsersModalOpen} onClose={onUsersModalClose}>
        <NewEmployee onClose={onUsersModalClose} />
      </ModalComponent>

      <ModalComponent isOpen={isRolesModalOpen} onClose={onRolesModalClose}>
        <NewRole onClose={onRolesModalClose} />
      </ModalComponent>
    </Box>
  );
};

export default ManageUsers;
