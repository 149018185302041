import {
  AuthType,
  Employee,
  MerchantAuthStore,
  Role,
  UpdateUser,
} from '@bofrak-backend/shared';
import { apiAdapter } from '@bofrak-backend/shared-ui';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { merchantAtom } from '../recoil/atoms';
import { paths } from '../utils/constants';

const RemoveFromStore = ({
  employee,
  store,
  onClose,
}: {
  store: MerchantAuthStore;
  employee?: Employee;
  onClose: () => void;
}) => {
  const [deleteClicked, setDeleteClicked] = useState(false);
  const toast = useToast();
  const client = useQueryClient();
  const { isLoading, mutateAsync: updateUser } = useMutation(
    'update-user',
    (data: UpdateUser) => apiAdapter.updateUser(data),
    {
      onSuccess: () => {
        toast({
          title: 'Sucess',
          description: 'Successfully removed employee from store',
          status: 'success',
        });
        client.invalidateQueries('get-users');
        onClose();
      },
      onError: (error: AxiosError) => {
        toast({
          title: 'Error',
          description: (
            (error.response?.data as any) ?? { message: error.message }
          ).message,
          status: 'error',
        });
      },
    },
  );

  const handleRemove = () => {
    if (!employee) {
      toast({
        title: 'Error',
        description: 'Employee not found',
        status: 'error',
      });
      return;
    }

    const auth_type = employee?.auth_user?.auth_type;

    if (!auth_type) {
      toast({
        title: 'Error',
        description: 'Employee does not have an auth type',
        status: 'error',
      });
      return;
    }

    updateUser({
      id: employee.id,
      remove_from_stores: [
        {
          ...store,
        },
      ],
      remove_from_roles: [
        {
          ...store,
        },
      ],
      auth_type,
    });
  };

  return (
    <Box mt={2}>
      {!deleteClicked && (
        <Button onClick={() => setDeleteClicked(true)} colorScheme="red">
          Remove From Store
        </Button>
      )}
      {deleteClicked && (
        <Stack spacing={2}>
          <Text color="red">
            {' '}
            Are you sure you want to remove the employee from this store?
          </Text>
          <Flex gap={2}>
            <Button
              onClick={handleRemove}
              colorScheme="red"
              isLoading={isLoading}>
              Yes
            </Button>
            <Button onClick={() => setDeleteClicked(false)} colorScheme="blue">
              No
            </Button>
          </Flex>
        </Stack>
      )}
    </Box>
  );
};

interface EditEmployeeModalProps {
  employee?: Employee | null;
  onClose: () => void;
  /**
   *  All possible roles available for the merchant.
   */
  roles: Role[];
}

const EditEmployeeModal = ({
  employee,
  onClose,
  roles,
}: EditEmployeeModalProps) => {
  const merchant = useRecoilValue(merchantAtom);
  const toast = useToast();
  const queryClient = useQueryClient();

  // Basic employee info
  const [firstName, setFirstName] = useState(
    employee?.auth_user?.given_name ?? '',
  );
  const [lastName, setLastName] = useState(
    employee?.auth_user?.family_name ?? '',
  );
  const [phoneNumber, setPhoneNumber] = useState(employee?.phone_number ?? '');
  const [email, setEmail] = useState(employee?.email ?? '');
  const [pin, setPin] = useState(employee?.auth_user?.pin ?? '');
  const [isActive, setIsActive] = useState(employee?.is_active ?? true);

  /**
   * We will store the *selected* role for each store in a local state object,
   * keyed by store_id.
   */
  const [storeRoleSelections, setStoreRoleSelections] = useState<
    Record<string, Role | undefined>
  >({});

  // Populate the initial roles for each store (if employee and employee.auth_user.stores exist)
  useEffect(() => {
    if (!employee?.auth_user?.stores) return;

    const initialSelections: Record<string, Role | undefined> = {};
    employee.auth_user.stores.forEach((store) => {
      const [firstRole] = store.roles ?? [];
      if (firstRole) {
        initialSelections[store.store_id] = roles.find(
          (r) => r.id === firstRole,
        );
      }
    });
    setStoreRoleSelections(initialSelections);
  }, [employee]);

  const { isLoading, mutateAsync: updateUserMutation } = useMutation(
    (data: UpdateUser) => apiAdapter.updateUser(data),
    {
      onSuccess: (data) => {
        if (data?.id) {
          toast({
            title: 'Employee updated successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          queryClient.invalidateQueries('get-users');
          onClose();
        }
      },
      onError: (error) => {
        toast({
          title: 'An error occurred',
          description: String(error),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  const handleRoleChange = (storeId: string, newRoleId: string) => {
    setStoreRoleSelections((prev) => ({
      ...prev,
      [storeId]: roles.find((r) => r.id === newRoleId),
    }));
  };

  const handleSave = async () => {
    if (!employee || !merchant) return;

    // Build the "add_to_roles" and "remove_from_roles" arrays
    const addToRoles: UpdateUser['add_to_roles'] = [];
    const removeFromRoles: UpdateUser['remove_from_roles'] = [];

    if (!employee.auth_user?.stores) {
      toast({
        title: 'Employee does not have any stores',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    employee.auth_user.stores.forEach((store: MerchantAuthStore) => {
      const newlySelectedRole = storeRoleSelections[store.store_id];
      const oldRoles = store.roles ?? [];

      if (newlySelectedRole) {
        addToRoles.push({
          store_id: store.store_id,
          roles: [newlySelectedRole.id],
        });

        removeFromRoles.push({
          store_id: store.store_id,
          roles: oldRoles.filter((r) => r !== newlySelectedRole.id),
        });
      }
    });

    const activateRoles = async () => {
      const user = await apiAdapter.getUser(employee.id);
      const add_to_roles = user?.auth_user?.stores;

      const auth_type = user?.auth_user?.auth_type;
      if (!auth_type) {
        toast({
          title: 'User does not have an auth type',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      await apiAdapter.updateUser({
        id: user?.id,
        add_to_roles,
        merchant_id: merchant?.id,
        auth_type,
      });
    };
    const deactivateRoles = async () => {
      const user = await apiAdapter.getUser(employee.id);

      const remove_from_roles = user?.auth_user?.stores;

      const auth_type = user?.auth_user?.auth_type;

      if (!auth_type) {
        toast({
          title: 'User does not have an auth type',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      await apiAdapter.updateUser({
        id: user?.id,
        remove_from_roles,
        merchant_id: merchant?.id,
        auth_type,
      });
    };

    const assignCognitoRole = async () => {
      const eId = employee.cognito_id as string;
      const merchantId = merchant?.id as string;

      if (!eId || !merchantId) return;
      const principal = await apiAdapter.getPrincipal(eId);
      if (!principal) {
        await apiAdapter.createPrincipal(eId, merchantId);
      }
      const storeIds = Object.keys(storeRoleSelections);
      for (const sid of storeIds) {
        const role = storeRoleSelections[sid];
        if (role) {
          await apiAdapter.assignRole(eId, role.id);
        }
      }
    };

    // Build the update payload dynamically, including only changed values
    const updatePayload: UpdateUser = {
      id: employee.id,
      auth_type: AuthType.Merchant,
    };

    if (firstName !== employee.auth_user?.given_name) {
      updatePayload.given_name = firstName;
    }
    if (lastName !== employee.auth_user?.family_name) {
      updatePayload.family_name = lastName;
    }
    if (email !== employee.email) {
      updatePayload.email = email;
    }
    if (phoneNumber !== employee.phone_number) {
      updatePayload.phone_number = phoneNumber;
    }
    if (pin !== employee.auth_user?.pin) {
      updatePayload.PIN = pin;
    }
    if (isActive !== employee.is_active) {
      updatePayload.is_active = isActive;
      if (isActive) await activateRoles();
      else await deactivateRoles();
    }

    // Only add role changes if there are any
    if (addToRoles.length > 0) {
      updatePayload.add_to_roles = addToRoles;
    }
    if (removeFromRoles.length > 0) {
      updatePayload.remove_from_roles = removeFromRoles;
    }

    try {
      if (Object.keys(updatePayload).length > 2) {
        // Ensure there's something to update besides id & auth_type
        await updateUserMutation(updatePayload);
        await assignCognitoRole();
      } else {
        toast({
          title: 'No changes detected',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // The toast onError in the mutation will handle error UI.
    }
  };

  if (!employee) {
    return <Text>Error: Employee not found</Text>;
  }

  return (
    <Box>
      <Center mt={4} width="full">
        <Text color="gray.700" fontWeight="bold" fontSize="lg">
          Edit Employee
        </Text>
      </Center>
      <Flex direction="column" p={2} gap={3}>
        <Input
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <Input
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />

        <Input
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <Input
          placeholder="Pin"
          value={pin}
          onChange={(e) => setPin(e.target.value)}
        />
      </Flex>

      {/* Accordion of Stores */}
      <Box mt={4}>
        <Accordion allowMultiple>
          {employee.auth_user?.stores?.map((store) => {
            const storeId = store.store_id;
            const storeName = store.store_name || store.store_id;
            const currentRoleSelection = storeRoleSelections[storeId];

            return (
              <AccordionItem key={storeId}>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      {storeName}

                      {store.roles?.length > 0 ? (
                        <Text as="span" color="gray.500" fontSize="sm" ml={2}>
                          Role:{' '}
                          {roles
                            .filter((x) => store.roles?.includes(x.id))
                            .map((x) => x.description)
                            .join(', ')}
                        </Text>
                      ) : null}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <HStack>
                    <Switch
                      id="is-active"
                      isChecked={isActive}
                      onChange={(e) => setIsActive(e.target.checked)}
                    />
                    <FormLabel mb={0}>Is Active</FormLabel>
                  </HStack>

                  <Heading my={1} size="sm">
                    Choose New Role
                  </Heading>

                  <RadioGroup
                    onChange={(val) => handleRoleChange(storeId, val)}
                    value={currentRoleSelection?.id}>
                    <Flex direction="column" gap={2}>
                      {roles.map((role) => (
                        <Radio key={role.id} value={role.id}>
                          {role.description}
                        </Radio>
                      ))}
                    </Flex>
                  </RadioGroup>
                  <RemoveFromStore
                    store={store}
                    employee={employee}
                    onClose={onClose}
                  />
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </Box>

      {/* Save button */}
      <Flex justify="flex-end" mt={6} gap={2}>
        <Link to={paths.addToStore.replace(':id', employee?.id)}>
          <Button>Add To Store</Button>
        </Link>
        <Button isLoading={isLoading} colorScheme="blue" onClick={handleSave}>
          Save Changes
        </Button>
      </Flex>
    </Box>
  );
};

export default EditEmployeeModal;
