import {
  CreateThreshold,
  Threshold,
  UpdateThreshold,
} from '@bofrak-backend/shared';
import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { apiAdapter } from '../../api/backend';
import { adminAtom, selectedStoreAtom } from '../../recoil/atoms';
import { AxiosError } from 'axios';

const ReceiptConfirmation = () => {
  const currentStore = useRecoilValue(selectedStoreAtom);
  const [currentThreshold, setCurrentThreshold] = useState<Threshold | null>();
  const [minArticles, setMinArticles] = useState<number>(0);
  const [minPurchaseValue, setMinPurchaseValue] = useState<number>(0);
  const navigate = useNavigate();
  const toast = useToast();
  const user = useRecoilValue(adminAtom);

  const { refetch } = useQuery(
    ['get-store-threshold', currentStore?.id],
    () =>
      apiAdapter.getStoreThreshold({
        merchant_id: currentStore?.merchant_id ?? '',
        store_id: currentStore?.id ?? '',
      }),
    {
      enabled: !!currentStore?.merchant_id && !!currentStore?.id,
      onSuccess: (data) => {
        if (data && data.store_id === currentStore?.id) {
          setCurrentThreshold(data);
          setMinArticles(data.min_quantity);
          setMinPurchaseValue(data.min_total_money);
        } else {
          setCurrentThreshold(null);
        }
      },
      onError: (error: AxiosError) => {
        toast({
          title: 'Failed to fetch store threshold',
          description: (
            (error.response?.data as any) ?? { message: error.message }
          ).message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        console.error(error);
      },
    },
  );

  const mutationOptions = {
    onSuccess: (data: Threshold) => {
      if (data) {
        refetch();
        toast({
          title: 'Threshold updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    },
    onError: (error: AxiosError) => {
      toast({
        title: 'Failed to update threshold',
        description: (
          (error.response?.data as any) ?? { message: error.message }
        ).message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.error(error);
    },
  };

  const { isLoading: isSaving, mutateAsync: saveThreshold } = useMutation(
    (data: CreateThreshold | UpdateThreshold) =>
      currentThreshold
        ? apiAdapter.updateStoreThreshold(data as UpdateThreshold)
        : apiAdapter.createStoreThreshold(data as CreateThreshold),
    mutationOptions,
  );

  const handleSave = async () => {
    if (!currentStore) {
      toast({
        title: 'Failed to update threshold',
        description: 'No store selected',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!user) {
      toast({
        title: 'Failed to update threshold',
        description: 'No user found',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    await saveThreshold({
      merchant_id: currentStore?.merchant_id ?? '',
      store_id: currentStore?.id ?? '',
      min_quantity: minArticles,
      min_total_money: minPurchaseValue,
      updated_by: {
        id: user?.id ?? '',
        name: user?.name ?? '',
      },
    });
  };

  return (
    <VStack p={4} spacing={4}>
      <Flex justify="flex-start" width="full">
        <Button size="sm" onClick={() => navigate(-1)} mb={4}>
          Back
        </Button>
      </Flex>
      <Text fontSize="lg" textAlign="center" fontWeight="bold">
        Receipt Confirmation
      </Text>
      {!currentThreshold && (
        <Alert status="warning">
          <AlertIcon />
          No Threshold exists on this Store
        </Alert>
      )}
      <Alert status="info">
        <AlertIcon />
        If any threshold is exceeded, an employee must confirm the receipt
        items.
      </Alert>

      <FormControl mt={4}>
        <Flex
          justify="space-between"
          p={4}
          align="center"
          bg="blue.50"
          boxShadow="lg"
          borderRadius="5px">
          <FormLabel>Minimum number of items on receipt</FormLabel>
          <NumberInput
            bg="white"
            width="120px"
            _focus={{
              boxShadow: 'none',
              border: 'none',
              outline: 'none',
            }}
            onChange={(valueString) => {
              setMinArticles(Number(valueString));
            }}
            value={minArticles}
            min={0}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </FormControl>

      <FormControl mt={4}>
        <Flex
          justify="space-between"
          p={4}
          align="center"
          bg="blue.50"
          boxShadow="lg"
          borderRadius="5px">
          <FormLabel>Minimum purchase value of receipt (MT)</FormLabel>
          <NumberInput
            bg="white"
            width="120px"
            _focus={{
              boxShadow: 'none',
              border: 'none',
              outline: 'none',
            }}
            onChange={(valueString) => {
              setMinPurchaseValue(Number(valueString));
            }}
            value={minPurchaseValue}
            min={0}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </FormControl>
      <Flex justify="flex-end" width="full">
        <Button isLoading={isSaving} onClick={handleSave} colorScheme="blue">
          {currentThreshold ? 'Update Threshold' : 'Create Threshold'}
        </Button>
      </Flex>
    </VStack>
  );
};

export default ReceiptConfirmation;
