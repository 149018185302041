import { MerchantAuthStore } from '@bofrak-backend/shared';
import { apiAdapter } from '@bofrak-backend/shared-ui';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import NewRole from '../components/NewRole';
import Modal from '../components/resuable/Modal';
import { merchantAtom } from '../recoil/atoms';

const AddEmployeeToStore = () => {
  const { id } = useParams();
  const merchant = useRecoilValue(merchantAtom);

  const { data: storesPage } = useQuery({
    queryKey: ['get-stores', merchant?.id],
    queryFn: () => apiAdapter.getStores(merchant?.id ?? ''),
    enabled: !!merchant,
    onError: (error: AxiosError) => {
      toast({
        title: 'Error',
        description: (
          (error.response?.data as any) ?? { message: error.message }
        ).message,
        status: 'error',
      });
    },
  });

  const { data: user } = useQuery({
    queryKey: ['get-user', id],
    queryFn: () => apiAdapter.getUser(id!),
    enabled: !!id,
  });

  const { data: rolesPage } = useQuery({
    queryKey: ['get-roles', merchant?.id],
    queryFn: () => apiAdapter.getRoles(merchant?.id ?? '', 1000),
    enabled: !!merchant,
  });

  const [storesToAdd, setStoresToAdd] = useState<string[]>([]);
  const [rolesToAdd, setRolesToAdd] = useState<Record<string, string>>({});

  const { onClose, onOpen, isOpen } = useDisclosure();

  const addToStores = async () => {
    if (!user) {
      toast({
        title: 'Error',
        description: 'User not found',
        status: 'error',
      });
      return;
    }

    if (!storesToAdd.length) {
      toast({
        title: 'Error',
        description: 'Please select a store to add the user to',
        status: 'error',
      });
      return;
    }

    if (!user.auth_user?.auth_type) {
      toast({
        title: 'Error',
        description: 'User does not have a valid credential type',
        status: 'error',
      });
      return;
    }

    const s = storesToAdd.map((storeId): MerchantAuthStore => {
      const r = rolesToAdd[storeId];
      return {
        store_id: storeId,
        store_name:
          storesPage?.stores.find((s) => s.id === storeId)?.name ?? '',
        merchant_id: merchant?.id ?? '',
        roles: r ? [r] : [],
      };
    });

    await apiAdapter.updateUser({
      id: user.id,
      add_to_stores: s,
      auth_type: user.auth_user.auth_type,
      add_to_roles: Object.entries(rolesToAdd).map(([store_id, role_id]) => {
        return { store_id, roles: [role_id] };
      }),
    });
  };

  const toast = useToast();
  const client = useQueryClient();
  const nav = useNavigate();

  const { isLoading, mutateAsync: updateUser } = useMutation(
    'update-user',
    addToStores,
    {
      onSuccess: () => {
        toast({
          title: 'Success',
          description: 'added user to store successfully',
          status: 'success',
        });
        client.invalidateQueries('get-users');
        client.invalidateQueries('get-roles');
        client.invalidateQueries('get-stores');
        nav(-1);
      },
      onError: (error: AxiosError) => {
        toast({
          title: 'Error',
          description: (
            (error.response?.data as any) ?? { message: error.message }
          ).message,
          status: 'error',
        });
      },
    },
  );

  const handleAdd = () => {
    updateUser();
  };

  const { stores: storesToShow } = storesPage ?? { stores: [] };

  const userStores = user?.stores ?? [];

  return (
    <Box p={4}>
      <Card>
        <CardHeader>
          <Text fontWeight="bold" fontSize="lg">
            Select the stores to add the user to.
          </Text>
        </CardHeader>
        <CardBody>
          <Stack spacing={2}>
            {storesToShow.map((store) => (
              <Stack key={store.id}>
                <Checkbox
                  checked={
                    storesToAdd.includes(store.id) ||
                    userStores.includes(store.id)
                  }
                  onChange={(e) => {
                    const checked = e.target.checked;
                    const includes = storesToAdd.includes(store.id);
                    if (checked && !includes) {
                      setStoresToAdd((prev) => [...prev, store.id]);
                    } else if (!checked) {
                      setStoresToAdd((prev) =>
                        [...prev].filter((id) => id != store.id),
                      );
                    }
                  }}>
                  {store.name}
                </Checkbox>
                {storesToAdd.includes(store.id) && (
                  <Box bg="gray.100" p={4} borderRadius="md">
                    <Text fontWeight={'bold'}>Roles:</Text>
                    <RadioGroup
                      display="flex"
                      gap={2}
                      onChange={(value) =>
                        setRolesToAdd({ ...rolesToAdd, [store?.id]: value })
                      }>
                      {rolesPage?.roles.map((role) => (
                        <Radio key={role.id} value={role.id}>
                          {role?.description}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </Box>
                )}
              </Stack>
            ))}
          </Stack>
        </CardBody>
        <CardFooter>
          <Flex justifyContent={'space-between'} w="100%">
            <Button onClick={onOpen}>New Role</Button>
            <Button
              colorScheme="green"
              onClick={handleAdd}
              isLoading={isLoading}>
              Add
            </Button>
          </Flex>
        </CardFooter>
      </Card>
      <Modal onClose={onClose} isOpen={isOpen}>
        <NewRole onClose={onClose} />
      </Modal>
    </Box>
  );
};

export default AddEmployeeToStore;
