import { Employee } from '@bofrak-backend/shared';
import { apiAdapter, Loader } from '@bofrak-backend/shared-ui';
import { useDisclosure, Box } from '@chakra-ui/react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { merchantAtom, rolesAtom, selectedStoreAtom } from '../recoil/atoms';
import EmployeeCard from './EmployeeCard';
import EditEmployeeModal from './EmployeeModal';
import ModalComponent from './resuable/Modal';

const EmployeeList = () => {
  const merchant = useRecoilValue(merchantAtom);
  const setRoles = useSetRecoilState(rolesAtom);
  const selectedStore = useRecoilValue(selectedStoreAtom);
  const { data: users, isLoading } = useQuery({
    queryKey: ['get-users', { merchant_id: merchant?.id }],
    queryFn: () =>
      apiAdapter.getUsers({
        merchant_id: merchant!.id!,
        limit: 250,
        store_id: selectedStore?.id,
      }),
    enabled: !!merchant,
  });

  const { data: rolesPage } = useQuery({
    queryKey: ['get-roles', { merchant_id: merchant?.id }],
    queryFn: () => apiAdapter.getRoles(merchant?.id as string, 3000),
    enabled: !!merchant?.id,
    onSuccess: (data) => {
      if (data) {
        setRoles(data.roles);
      }
    },
  });

  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null,
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleEditUser = (employee: Employee) => {
    setSelectedEmployee(employee);
    onOpen();
  };

  if (isLoading) return <Loader />;

  return (
    <Box width="full" p={4} boxShadow="md">
      {/* Employee Rows */}
      {users?.employees.map((employee, index) => (
        <EmployeeCard
          employee={employee}
          index={index}
          usersLength={users?.employees.length ?? 0}
          handleEditUser={handleEditUser}
        />
      ))}

      {/* Edit User Modal */}
      <ModalComponent isOpen={isOpen} onClose={onClose}>
        <EditEmployeeModal
          roles={rolesPage ? rolesPage.roles : []}
          employee={selectedEmployee}
          onClose={onClose}
        />
      </ModalComponent>
    </Box>
  );
};

export default EmployeeList;
