import {
  Employee,
  PosDevice,
  SoftDeleteFlag,
  UpdatePosDevice,
} from '@bofrak-backend/shared';
import { Loader } from '@bofrak-backend/shared-ui';
import { apiAdapter } from '@bofrak-backend/shared-ui/src/utils/api';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  Switch,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEdit } from 'react-icons/fa';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import ModalComponent from '../components/resuable/Modal';
import {
  merchantAtom,
  posDevicesAtom,
  selectedStoreAtom,
  storesAtom,
} from '../recoil/atoms';
import AddPosDevice from './add-pos-device';
import { AxiosError } from 'axios';

interface PosDeviceProps {
  posDevices: PosDevice[] | undefined;
}

const PosDevicesList = ({ posDevices }: PosDeviceProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPos, setSelectedPos] = useState<PosDevice | null>(null);
  const merchant = useRecoilValue(merchantAtom);
  const queryClient = useQueryClient();
  const toast = useToast();
  const stores = useRecoilValue(storesAtom);

  // useForm hook from react-hook-form
  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      name: '',
      prefix: '',
      activated: false,
    },
  });

  // Collect unique current_user_ids
  const userIds = Array.from(
    new Set(posDevices?.map((pos) => pos.current_user_id).filter(Boolean)),
  ) as string[];

  // Fetch user data for each user ID
  const userQueries = useQueries(
    userIds.map((userId) => ({
      queryKey: ['user', userId],
      queryFn: () => apiAdapter.getUser(userId),
      enabled: !!userId,
    })),
  );

  // Map user IDs to user data
  const userMap: { [key: string]: Employee } = {};
  userQueries.forEach((query) => {
    if (query.data) {
      userMap[query.data.id] = query.data;
    }
  });

  const handleClickPos = (pos: PosDevice) => {
    setSelectedPos(pos);
    reset({
      name: pos.name,
      prefix: pos.prefix,
      activated: pos.activated,
    });
    onOpen();
  };

  const { mutateAsync, isLoading } = useMutation(
    (data: UpdatePosDevice) => apiAdapter.updatePOSDevice(data),
    {
      onSuccess: (data) => {
        if (!data)
          console.log('No data returned from update POS device mutation');
        onClose();
        toast({
          title: 'POS device updated',
          description: 'POS device has been updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        queryClient.invalidateQueries('get-pos-devices');
      },
      onError: (error: AxiosError) => {
        console.log(error);
        toast({
          title: 'Could not update POS device',
          description: (
            (error.response?.data as any) ?? { message: error.message }
          ).message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  const onSubmit = async (data: any) => {
    if (!merchant) {
      toast({
        title: 'Merchant not found',
        description: 'Could not find merchant',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!selectedPos) {
      toast({
        title: 'POS device not found',
        description: 'Could not find POS device',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const dtoObject: UpdatePosDevice = {
      name: data.name,
      activated: data.activated,
      prefix: data.prefix,
      merchant_id: merchant?.id,
      id: selectedPos?.id,
    };

    await mutateAsync(dtoObject);
  };

  if (!merchant) return <Loader />;

  return (
    <Box width="full" p={4}>
      {/* POS Rows */}
      <VStack>
        {posDevices?.map((pos) => {
          const currentUser = pos.current_user_id
            ? userMap[pos.current_user_id]
            : null;
          const currentStore = stores
            ? stores.stores.find((store) => store.id === pos.store_id)
            : null;

          return (
            <Card key={pos.id} width={'full'}>
              <CardHeader>
                <HStack justify="space-between">
                  <Heading size="sm">{pos.name}</Heading>
                  <HStack spacing={0}>
                    <Heading size="sm">{currentStore?.name}</Heading>
                    <IconButton
                      icon={<FaEdit />}
                      aria-label="Edit POS"
                      size="sm"
                      bg="transparent"
                      onClick={() => handleClickPos(pos)}
                    />
                  </HStack>
                </HStack>
              </CardHeader>

              <CardBody py={1}>
                <HStack width="full" justifyContent={'space-between'}>
                  <Heading size="xs" textTransform="uppercase">
                    User
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {currentUser?.name ?? 'NA'}
                  </Text>
                </HStack>
                <HStack width="full" justifyContent={'space-between'}>
                  <Heading size="xs" textTransform="uppercase">
                    Prefix
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {pos.prefix}
                  </Text>
                </HStack>
                <HStack width="full" justifyContent={'space-between'}>
                  <Heading size="xs" textTransform="uppercase">
                    Activated
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {pos.activated ? 'Yes' : 'No'}
                  </Text>
                </HStack>
                <HStack width="full" justifyContent={'space-between'}>
                  <Heading size="xs" textTransform="uppercase">
                    Last Updated
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {moment(pos.updated_at).format('DD/MM/YYYY @ HH:mm')}
                  </Text>
                </HStack>
              </CardBody>
            </Card>
          );
        })}
      </VStack>

      {/* Modal for Editing POS */}
      <ModalComponent isOpen={isOpen} onClose={onClose}>
        <Center mt={10} width={'full'}>
          <Text color={'gray.700'} fontWeight={'bold'}>
            Update POS
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input {...register('name')} placeholder="Enter name" />
            </FormControl>

            <FormControl>
              <FormLabel>Prefix</FormLabel>
              <Input {...register('prefix')} placeholder="Enter prefix" />
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0">Status</FormLabel>
              <Switch
                {...register('activated')}
                isChecked={watch('activated')} // Watching for the switch's value
              />
            </FormControl>

            <Flex justify="flex-end">
              <Button isLoading={isLoading} type="submit" colorScheme="blue">
                Update
              </Button>
            </Flex>
          </VStack>
        </form>
      </ModalComponent>
    </Box>
  );
};

const ManagePosDevices = () => {
  const merchant = useRecoilValue(merchantAtom);
  const {
    isOpen: isPosModalOpen,
    onOpen: onPosModalOpen,
    onClose: onPosModalClose,
  } = useDisclosure();
  const currentStore = useRecoilValue(selectedStoreAtom);
  const [posDevicesPage, setPosDevicesPage] = useRecoilState(posDevicesAtom);

  const { data, isLoading } = useQuery(
    [
      'get-pos-devices',
      { store_id: currentStore?.id, merchant_id: merchant?.id },
      currentStore?.id,
      merchant?.id,
    ],
    () =>
      apiAdapter.getPOSDevices({
        store_id: currentStore?.id ?? '',
        merchant_id: merchant?.id ?? '',
        limit: 100,
        include_deleted: SoftDeleteFlag.EXCLUDE_DELETED,
      }),
    {
      enabled: !!currentStore && !!merchant,
    },
  );

  useEffect(() => {
    if (data?.pos_devices) {
      setPosDevicesPage(data);
    }
  }, [data]);

  if (isLoading) return <Loader />;

  return (
    <Box p={4}>
      <Flex justify={'flex-end'} align="center">
        <Button size="sm" onClick={onPosModalOpen} colorScheme="blue" my={4}>
          + Add POS
        </Button>
      </Flex>

      {posDevicesPage && posDevicesPage?.pos_devices?.length > 0 ? (
        <PosDevicesList posDevices={posDevicesPage?.pos_devices} />
      ) : (
        <Center mt={4}>
          <Text>No POS devices found</Text>
        </Center>
      )}

      <ModalComponent isOpen={isPosModalOpen} onClose={onPosModalClose}>
        <AddPosDevice onClose={onPosModalClose} />
      </ModalComponent>
    </Box>
  );
};

export default ManagePosDevices;
