import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import {
  CreateDiscount,
  Discount,
  DiscountType,
  UpdateDiscount,
} from '@bofrak-backend/shared';
import { useNavigate } from 'react-router-dom';
import { Loader } from '@bofrak-backend/shared-ui';
import { apiAdapter } from '@bofrak-backend/shared-ui/src/utils/api';
import ModalComponent from '../components/resuable/Modal';
import { selectedStoreAtom } from '../recoil/atoms';
import { AxiosError } from 'axios';
import { MdDelete } from 'react-icons/md';

interface DiscountsProps {
  discounts: Discount[] | undefined;
}

const DiscountsList = ({ discounts }: DiscountsProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDiscount, setSelectedDiscount] = useState<Discount | null>(
    null,
  );
  const queryClient = useQueryClient();
  const currentStore = useRecoilValue(selectedStoreAtom);
  const toast = useToast();

  const discountTypes = Object.values(DiscountType);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      discount_amount: 0,
      type: '',
    },
  });

  // When a row is clicked, open the update modal and load discount details.
  const handleClickDiscount = (discount: Discount) => {
    setSelectedDiscount(discount);
    reset({
      name: discount.name,
      discount_amount: discount.discount_amount,
      type: discount.type,
    });
    onOpen();
  };

  // Mutation for updating discount
  const { mutateAsync: updateDiscountMutation, isLoading: isUpdatingDiscount } =
    useMutation(
      'update-discount',
      (data: UpdateDiscount) => apiAdapter.updateDiscount(data),
      {
        onSuccess: (data) => {
          if (data) {
            onClose();
            queryClient.invalidateQueries('get-discounts');
            toast({
              title: 'Discount updated successfully',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          }
        },
        onError: (error: AxiosError) => {
          toast({
            title: 'Failed to update discount',
            description: (
              (error.response?.data as any) ?? { message: error.message }
            ).message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          console.error(error);
        },
      },
    );

  // Mutation for deleting discount
  const { isLoading: isDeleting, mutateAsync: deleteDiscountMutation } =
    useMutation(
      'delete-discount',
      (discount: Discount) =>
        apiAdapter.deleteDiscount({
          store_id: currentStore?.id as string,
          id: discount.id,
        }),
      {
        onSuccess: () => {
          toast({
            title: 'Discount deleted successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          queryClient.invalidateQueries('get-discounts');
        },
        onError: (error: AxiosError) => {
          toast({
            title: 'Failed to delete discount',
            description: (
              (error.response?.data as any) ?? { message: error.message }
            ).message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );

  // Called when clicking the delete icon in a discount row.
  const handleDeleteDiscount = async (
    discount: Discount,
    e: React.MouseEvent,
  ) => {
    e.stopPropagation(); // Prevent opening the update modal.
    try {
      await deleteDiscountMutation(discount);
    } catch (error) {
      console.log(error);
    }
  };

  // Called when clicking the Delete button in the update modal.
  const handleDeleteSelectedDiscount = async () => {
    if (!selectedDiscount) return;
    try {
      await deleteDiscountMutation(selectedDiscount);
      setSelectedDiscount(null);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data: any) => {
    if (!currentStore?.id || !selectedDiscount) return;

    const dtoObject: UpdateDiscount = {
      id: selectedDiscount.id,
      store_id: currentStore.id,
    };

    if (data.name !== selectedDiscount.name) dtoObject.name = data.name;
    if (Number(data.discount_amount) !== selectedDiscount.discount_amount)
      dtoObject.discount_amount = Number(data.discount_amount);
    if (data.type !== selectedDiscount.type) dtoObject.type = data.type;

    try {
      await updateDiscountMutation(dtoObject);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box width="full" p={3} boxShadow="md">
      {/* Table Header */}
      <Table maxW="full" variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Amount</Th>
            <Th>Type</Th>
          </Tr>
        </Thead>
        <Tbody>
          {discounts?.map((discount, index) => (
            <Tr
              key={index}
              bg={index % 2 === 0 ? '#F7FAFC' : 'white'}
              onClick={() => handleClickDiscount(discount)}
              cursor="pointer">
              <Td>{discount.name}</Td>
              <Td>{discount.discount_amount}</Td>
              <Td>
                <HStack>
                  <Text fontWeight={'sm'}>{discount.type}</Text>
                  <IconButton
                    bg="transparent"
                    color="red"
                    aria-label="Delete Discount"
                    icon={<MdDelete />}
                    isLoading={isDeleting}
                    onClick={(e) => handleDeleteDiscount(discount, e)}
                  />
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Modal for Editing Discount */}
      <ModalComponent isOpen={isOpen} onClose={onClose}>
        <Center mt={10} width="full">
          <Text color="gray.700" fontWeight="bold">
            Update Discount
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input {...register('name')} placeholder="Enter discount name" />
            </FormControl>

            <FormControl>
              <FormLabel>Amount</FormLabel>
              <Input
                {...register('discount_amount')}
                placeholder="Enter discount amount"
                type="number"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select {...register('type')} placeholder="Select discount type">
                {discountTypes.map((type, i) => (
                  <option key={i} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
            </FormControl>

            <HStack justifyContent="space-around" width="full">
              <Button
                type="button"
                onClick={handleDeleteSelectedDiscount}
                colorScheme="red"
                isLoading={isDeleting}>
                Delete
              </Button>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isUpdatingDiscount}>
                Update
              </Button>
            </HStack>
          </VStack>
        </form>
      </ModalComponent>
    </Box>
  );
};

const ManageDiscounts = () => {
  const {
    isOpen: isDiscountModalOpen,
    onOpen: onDiscountModalOpen,
    onClose: onDiscountModalClose,
  } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const currentStore = useRecoilValue(selectedStoreAtom);
  const [discountsPage, setDiscountsPage] = useState<Discount[]>([]);
  const queryClient = useQueryClient();

  const discountTypes = [
    DiscountType.FIXED_AMOUNT,
    DiscountType.FIXED_PERCENT,
    DiscountType.VARIABLE_AMOUNT,
    DiscountType.VARIABLE_PERCENT,
    DiscountType.DISCOUNT_BY_POINTS,
  ];

  const { status } = useQuery(
    ['get-discounts', currentStore?.id],
    () =>
      apiAdapter.getDiscounts({
        store_id: currentStore?.id ?? '',
        limit: 250,
      }),
    {
      enabled: !!currentStore?.id,
      onSuccess: (data) => {
        if (data) {
          setDiscountsPage(data.discounts);
        }
      },
    },
  );

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      amount: 0,
      type: '',
    },
  });

  const selectedType = watch('type');

  const { isLoading, mutateAsync } = useMutation(
    'create-discount',
    (data: CreateDiscount) => apiAdapter.createDiscount(data),
    {
      onSuccess: (data) => {
        if (data) {
          onDiscountModalClose();
          queryClient.invalidateQueries('get-discounts');
          reset();
          toast({
            title: 'Discount created successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      },
      onError: (error: AxiosError) => {
        toast({
          title: 'Failed to create discount',
          description: (
            (error.response?.data as any) ?? { message: error.message }
          ).message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        console.error(error);
      },
    },
  );

  const onSubmit = async (data: any) => {
    const dataObj: CreateDiscount = {
      name: data.name as string,
      discount_amount: Number(data.amount),
      type: data.type as DiscountType,
      store_id: currentStore?.id as string,
    };
    try {
      await mutateAsync(dataObj);
    } catch (error) {
      console.log(error);
    }
  };

  if (status === 'loading') return <Loader />;

  return (
    <Box p={4}>
      <Button size="sm" onClick={() => navigate(-1)} mb={4}>
        Back
      </Button>
      <Text fontSize="lg" textAlign="center" fontWeight="bold">
        Discounts
      </Text>
      <Flex justify="flex-end">
        <Button
          size="sm"
          mb={4}
          onClick={onDiscountModalOpen}
          colorScheme="blue">
          + Discount
        </Button>
      </Flex>
      {discountsPage.length > 0 ? (
        <DiscountsList discounts={discountsPage} />
      ) : (
        <Center mt={4}>
          <Text>No discounts found</Text>
        </Center>
      )}

      {/* Modal for Creating Discount */}
      <ModalComponent
        isOpen={isDiscountModalOpen}
        onClose={onDiscountModalClose}>
        <Center mt={10} width="full">
          <Text color="gray.700" fontWeight="bold">
            Add Discount
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name', { required: 'This field is required' })}
                placeholder="Enter discount name"
              />
              {errors.name && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>
                {selectedType === 'FIXED_AMOUNT'
                  ? 'Amount'
                  : selectedType === DiscountType.FIXED_PERCENT
                    ? 'Percentage'
                    : 'Amount'}
              </FormLabel>
              <Input
                {...register('amount', { required: 'This field is required' })}
                placeholder="Enter discount amount"
                type="number"
              />
              {errors.amount && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select
                {...register('type', { required: 'This field is required' })}
                placeholder="Select discount type">
                {discountTypes.map((type, i) => (
                  <option key={i} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
              {errors.type && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <Flex justify="flex-end" width="full">
              <Button isLoading={isLoading} type="submit" colorScheme="blue">
                Add
              </Button>
            </Flex>
          </VStack>
        </form>
      </ModalComponent>
    </Box>
  );
};

export default ManageDiscounts;
