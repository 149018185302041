import {
  LoyaltyProgram,
  LoyaltyProgramPage,
  UpdateLoyaltyProgram,
} from '@bofrak-backend/shared';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Loader } from '@bofrak-backend/shared-ui';
import { merchantAtom } from '../../recoil/atoms';
import { apiAdapter } from '../../api/backend';

const LoyaltyProgramComponent = () => {
  const merchant = useRecoilValue(merchantAtom);
  const [loyalties, setLoyalties] = useState<LoyaltyProgram[] | undefined>([]);
  const [editedLoyalty, setEditedLoyalty] = useState<Record<string, number>>(
    {},
  );
  const [updatedLoyalty, setUpdatedLoyalty] = useState<
    LoyaltyProgram | undefined
  >(undefined);
  const [isSaved, setIsSaved] = useState<Record<string, boolean>>({});
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();

  const { status } = useQuery(
    ['loyalty', merchant?.id as string],
    () =>
      apiAdapter.getLoyaltyPrograms({
        merchant_id: merchant?.id as string,
      }),

    {
      enabled: !!merchant?.id,
      onSuccess: (data: LoyaltyProgramPage) => {
        if (!data) return;
        setLoyalties(data.programs);
      },
    },
  );

  const handleInputChange = (id: string, value: number) => {
    setEditedLoyalty((prev) => ({ ...prev, [id]: value }));
    setIsSaved((prev) => ({ ...prev, [id]: false }));
  };

  const { mutateAsync: updateLoyaltyMutation, isLoading: isUpdatingLoyalty } =
    useMutation(
      'updateLoyalty',
      (data: UpdateLoyaltyProgram) =>
        apiAdapter.updateLoyaltyProgram({
          updateLoyaltyProgramDto: data,
          merchant_id: merchant?.id as string,
        }),
      {
        onSuccess: (data) => {
          if (!data) return;
          queryClient.invalidateQueries('loyalty');
          toast({
            title: 'Loyalty updated',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          toast({
            title: 'An error occurred',
            description: error as string,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );

  const handleSave = async (id: string) => {
    if (!updatedLoyalty) return;
    try {
      await updateLoyaltyMutation({
        merchant_id: merchant?.id as string,
        id: updatedLoyalty.id,
        points_per_threshold: editedLoyalty[id],
        store_id: updatedLoyalty.store_id,
        description: updatedLoyalty.description,
        is_active: updatedLoyalty.is_active,
        name: updatedLoyalty.name,
        loyalty_type: updatedLoyalty.loyalty_type,
        threshold_amount: updatedLoyalty.threshold_amount,
      });
      setIsSaved((prev) => ({ ...prev, [id]: true }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSwitchChange = (id: string) => {
    const updatedLoyalties = loyalties?.map((loyalty) =>
      loyalty.id === id
        ? { ...loyalty, is_active: !loyalty.is_active }
        : loyalty,
    );
    setLoyalties(updatedLoyalties);
    // check if the value has changed
    if (
      loyalties?.find((loyalty) => loyalty.id === id)?.is_active !==
      !loyalties?.find((loyalty) => loyalty.id === id)?.is_active
    ) {
      setUpdatedLoyalty({
        ...(loyalties?.find((loyalty) => loyalty.id === id) as LoyaltyProgram),
        is_active: !loyalties?.find((loyalty) => loyalty.id === id)?.is_active,
      });
      try {
        console.log('Update Loyalty');
      } catch (err) {
        console.log(err);
      }
    }
  };

  if (status === 'loading') return <Loader />;

  return (
    <Box p={4}>
      <Button size="sm" onClick={() => navigate(-1)} mb={4}>
        Back
      </Button>
      <Text fontSize="lg" textAlign="center" fontWeight="bold">
        Loyalty Program
      </Text>
      {loyalties?.map((loyalty) => (
        <Box key={loyalty.id} mb={4}>
          <Switch
            isChecked={loyalty.is_active}
            onChange={() => handleSwitchChange(loyalty.id)}
            size="lg"
            isDisabled={isUpdatingLoyalty}
          />
          <Flex
            p={4}
            my={4}
            bg={'white'}
            justify="space-between"
            align="center"
            border="1px"
            borderColor="transparent"
            boxShadow={'lg'}
            borderRadius="md"
            width={'full'}>
            <Text width={'100%'} fontWeight="bold">
              {loyalty.name}
            </Text>
            <FormControl mt={4}>
              <FormLabel textAlign={'right'} fontSize={'sm'} color={'gray.400'}>
                Points per threshold
              </FormLabel>
              <Flex align="end" justify={'flex-end'} width={'full'}>
                <Input
                  value={
                    editedLoyalty[loyalty.id] ?? loyalty.points_per_threshold
                  }
                  onChange={(e) =>
                    handleInputChange(loyalty.id, Number(e.target.value))
                  }
                  type="number"
                  step="0.01"
                  width="100px"
                />
                {/* {!isSaved[loyalty.id] && ( */}
                <IconButton
                  aria-label="Save"
                  variant={'none'}
                  isLoading={isUpdatingLoyalty}
                  icon={<FaSave />}
                  color={'green.400'}
                  isDisabled={isSaved[loyalty.id] || isUpdatingLoyalty}
                  ml={2}
                  onClick={() => handleSave(loyalty.id)}
                />
                {/* )} */}
              </Flex>
            </FormControl>
          </Flex>
          <Text ml={2} mt={2} fontSize="sm" color="gray.600">
            {loyalty.description}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export default LoyaltyProgramComponent;
