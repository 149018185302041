import { Employee, MerchantAuthStore, Store } from '@bofrak-backend/shared';
import {
  Card,
  CardHeader,
  Heading,
  CardBody,
  Stack,
  StackDivider,
  List,
  ListItem,
  ListIcon,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { MdCheckCircle } from 'react-icons/md';
import { useRecoilValue } from 'recoil';
import { rolesAtom, storesAtom } from '../recoil/atoms';
import { flatten } from 'lodash';

const EmployeeCard = ({
  employee,
  handleEditUser,
}: {
  employee: Employee;
  index: number;
  usersLength: number;
  handleEditUser: (employee: Employee) => void;
}) => {
  const storesPage = useRecoilValue(storesAtom);
  const roles = useRecoilValue(rolesAtom);

  const employeeStores: Store[] =
    storesPage?.stores.filter((store) =>
      employee.stores
        .map((x) => x.toLowerCase())
        .includes(store.id.toLowerCase()),
    ) ?? [];

  const employeeStoreRoles: MerchantAuthStore[] =
    employee.auth_user?.stores ?? [];

  return (
    <Card my={2} onClick={() => handleEditUser(employee)} cursor="pointer">
      <CardHeader pb={1}>
        <Heading
          textTransform={'capitalize'}
          color={employee.is_active ? 'green.500' : 'red.500'}
          size="md">
          {`${employee.auth_user?.given_name} ${employee.auth_user?.family_name}`}
        </Heading>
        <Text
          as={'span'}
          color={'gray.500'}
          fontWeight={'normal'}
          fontSize={'xs'}>
          Joined {dayjs(employee?.created_at).format('DD-MMM-YYYY')}{' '}
        </Text>
      </CardHeader>

      <CardBody pt={0}>
        <Heading size="sm">Stores</Heading>
        <Stack divider={<StackDivider />} spacing="2">
          <List spacing={0}>
            {employeeStores?.map((store) => {
              const storeRoles = flatten(
                employeeStoreRoles
                  .filter(
                    (x) => x.store_id.toLowerCase() === store.id.toLowerCase(),
                  )
                  .map((x) => x.roles),
              );

              const storeRolesDescriptions = roles
                .filter((r) => storeRoles.includes(r.id))
                .map((r) => r.description)
                .join(', ');

              return (
                <ListItem key={store.id}>
                  <ListIcon
                    size={'xx-small'}
                    as={MdCheckCircle}
                    color="green.500"
                  />
                  {store.name}
                  {storeRolesDescriptions.length > 0
                    ? ' (' + storeRolesDescriptions + ')'
                    : ' (No role)'}
                </ListItem>
              );
            })}
          </List>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default EmployeeCard;
