import { Route, Routes } from 'react-router-dom';
import { paths } from './utils/constants';

import AddUser from './pages/add-user';
import EditUser from './pages/edit-user';
import Home from './pages/home';
import NotFound from './pages/not-found';
import EditPosDevice from './pages/edit-pos-device';
import LoyaltyProgram from './pages/loyalty';
import ReceiptConfirmation from './pages/receiptsConfirmation';
import ManageUsers from './pages/manage-users';
import ManagePosDevices from './pages/manage-pos-devices';
import ManageTaxes from './pages/manage-taxes';
import ManagePaymentTypes from './pages/manage-payment-types';
import ManageDiscounts from './pages/manage-discounts';
import ManageStores from './pages/manage-stores';
import AddEmployeeToStore from './pages/add-employee-to-store';

export const Router = () => {
  return (
    <Routes>
      <Route path={paths.home} element={<Home />} />
      <Route path={paths.manageUsers} element={<ManageUsers />} />
      <Route path={paths.stores} element={<ManageStores />} />
      <Route path={paths.posDevices} element={<ManagePosDevices />} />
      <Route path={paths.taxes} element={<ManageTaxes />} />
      <Route path={paths.discounts} element={<ManageDiscounts />} />
      <Route path={paths.paymentTypes} element={<ManagePaymentTypes />} />
      <Route path={paths.editPosDevice} element={<EditPosDevice />} />
      <Route path={paths.editUser} element={<EditUser />} />
      <Route path={paths.addUser} element={<AddUser />} />{' '}
      <Route path={paths.loyalties} element={<LoyaltyProgram />} />
      <Route path={paths.receipts} element={<ReceiptConfirmation />} />
      <Route path={paths.addToStore} element={<AddEmployeeToStore />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
