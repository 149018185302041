import { Policy, Role } from '@bofrak-backend/shared';
import { Loader } from '@bofrak-backend/shared-ui';
import { Card, CardBody, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { apiAdapter } from '../api/backend';
import { merchantAtom } from '../recoil/atoms';
import EditRole from './EditRole';
import ModalComponent from './resuable/Modal';
import { useMemo } from 'react';

interface RoleCardProps {
  role: Role;
}

async function getActionsInRole(roleId: string) {
  // fetch policies in role
  const policies = await apiAdapter.getPoliciesInRole(roleId, 500);
  // get all the actions in the policies
  const actions = await Promise.all(
    policies.policies.map((policy: Policy) =>
      apiAdapter.getAction(policy.action_id),
    ),
  );
  return actions;
}

function RoleCard({ role }: RoleCardProps) {
  const { data: actions, isLoading } = useQuery({
    queryKey: `${role.id}-actions`,
    queryFn: () => getActionsInRole(role.id),
    keepPreviousData: true,
  });

  const { data: principals } = useQuery({
    queryKey: `${role.id}-principals`,
    queryFn: async () =>
      (await apiAdapter.getPrincipalsInRole(role.id, 100)).principals,
    keepPreviousData: true,
  });

  const {
    isOpen: isEditRoleModalOpen,
    onOpen: onEditRoleModalOpen,
    onClose: onEditRoleModalClose,
  } = useDisclosure();

  const apps = useMemo(
    () =>
      actions?.filter((action) => action.description.startsWith('Access')) ??
      [],
    [actions],
  );

  let numEmployeesEnding = '';
  if (principals && principals.length > 1) numEmployeesEnding = 's';
  let numEmployeesMsg = `${principals?.length} Employee${numEmployeesEnding}`;
  if (principals && principals.length == 0) numEmployeesMsg = 'No Employees';

  return isLoading ? (
    <Loader />
  ) : (
    <Card
      _hover={{
        shadow: 'lg',
      }}
      cursor="pointer"
      onClick={() => onEditRoleModalOpen()}>
      <CardBody>
        <Flex justifyContent="space-between">
          <Text
            width="100%"
            // border='1px solid red'
            textAlign="left">
            {role.description}
          </Text>
          <Text
            width="100%"
            // border='1px solid green'
            textAlign="center">
            {numEmployeesMsg}
          </Text>
          <Text
            width="100%"
            // border='1px solid blue'
            textAlign="right">
            {`${apps.length} App(s)`}
          </Text>
        </Flex>
      </CardBody>
      <ModalComponent
        isOpen={isEditRoleModalOpen}
        onClose={onEditRoleModalClose}>
        <EditRole onClose={onEditRoleModalClose} roleId={role.id} />
      </ModalComponent>
    </Card>
  );
}

export default function RoleList() {
  const merchant = useRecoilValue(merchantAtom);

  const { data: rolesPage, isLoading } = useQuery({
    queryKey: ['get-roles', { merchant_id: merchant?.id }],
    queryFn: () => apiAdapter.getRoles(merchant?.id as string, 1000),
    enabled: !!merchant?.id,
  });

  console.log(
    rolesPage?.roles.map(({ description, id }) => ({
      description,
      id,
    })),
  );

  if (isLoading) return <Loader />;

  return (
    <Flex direction="column" gap={5}>
      <pre></pre>
      <Flex p={4} borderBottom="1px solid #E2E8F0" color="#000" fontSize="sm">
        <Text fontWeight="bold" flex={1} textAlign="left">
          Role Name
        </Text>
        <Text fontWeight="bold" flex={1} textAlign="center">
          Number of employees
        </Text>
        <Text fontWeight="bold" flex={1} textAlign="right">
          Number of apps
        </Text>
      </Flex>
      {rolesPage?.roles.map((role: Role, key: number) => (
        <RoleCard key={key} role={role} />
      ))}
    </Flex>
  );
}
